<template>
  <div class="about">
    <h1>{{ t('This is an about page') }}</h1>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useTranslation } from '@/locales'

export default defineComponent({
  setup(props) {
    const { t } = useTranslation()
    return { t }
  },
})
</script>
