
import { defineComponent } from 'vue'
import { useTranslation } from '@/locales'

export default defineComponent({
  setup(props) {
    const { t } = useTranslation()
    return { t }
  },
})
